import React from "react";

const Contactpage = () => {
  return (
    <div className="pt-4">
      <div>
        {" "}
        <p>contact us</p>
      </div>
      <h2 className="">get in touch</h2>
      <p>Please feel free to contact us if you have any further questions</p>
      <div>
        <label htmlFor="firstName">First name</label>
        <input type="text" />
      </div>
    </div>
  );
};

export default Contactpage;
